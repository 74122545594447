import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo }  from '@angular/fire/auth-guard';
//import { canActivate } from '@angular/fire/auth-guard';
import {UrlTree,ActivatedRoute} from "@angular/router";

const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);
// const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']); 
// const redirectUnauthorizedToLogin = (redirectTo:string) => redirectUnauthorizedTo('login',{queryParams:{ redirectTo:$(redirectTo)}); 
// redirectUnauthorizedTo(`/login?redirectTo=${router.queryParams['merchantId']}&router=${router.url}&auth=${auth}`);
export const redirectUnauthorizedToLogin = (router: ActivatedRoute, auth: any, afterLoginRedirect: string) => 
  redirectUnauthorizedTo(`/login?redirectTo=${router.url}&merchantId=${router.queryParams['merchantId']}&amount=${router.queryParams['amount']}&referenceId=${router.queryParams['referenceId']}`);

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin}
    // ...canActivate(redirectUnauthorizedToLogin(this.router.url)) 
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin}
    // ...canActivate(redirectUnauthorizedToLogin) 
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule),
  },
  {
    path: 'pay',
    loadChildren: () => import('./pay/pay.module').then( m => m.PayPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin}
    // ...canActivate(redirectUnauthorizedToLogin) 
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
